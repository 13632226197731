"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pinoLevelMap = void 0;
exports.setLogger = setLogger;
exports.currentLogger = currentLogger;
const logger_not_configured_error_1 = require("./logger-not-configured.error");
let logger;
function setLogger(newLogger) {
  logger = newLogger;
}
function currentLogger() {
  if (logger === undefined) {
    throw new logger_not_configured_error_1.LoggerNotConfiguredError();
  }
  return logger;
}
exports.pinoLevelMap = new Map([['Critical', 'fatal'], ['Error', 'error'], ['Warning', 'warn'], ['Informational', 'info'], ['Verbose', 'trace']]);
