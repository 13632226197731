"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApolloLoggerPlugin = void 0;
const graphql_1 = require("graphql");
class ApolloLoggerPlugin {
  logger;
  dataFilter;
  constructor(logger, dataFilter) {
    this.logger = logger;
    this.dataFilter = dataFilter;
  }
  /**
   * Server Lifecycle Handlers
   */
  async serverWillStart() {
    this.logger.info('Apollo Server starting');
  }
  /**
   * requestDidStart
   * Triggered at the beginning of every request cycle, and returns an object (GraphQLRequestListener)
   * that has the functions for each request lifecycle event.
   */
  async requestDidStart(requestContext) {
    this.logger.debug('requestDidStart');
    this.logger.info(requestContext.request.operationName ?? '<none>', 'Request Operation');
    this.logger.info(requestContext.request.query ?? '<none>', 'Request Query');
    this.logger.debug(this.filterVariables(requestContext), 'Request variables');
    return {
      parsingDidStart: async requestContext => {
        this.logger.debug('parsingDidStart');
      },
      validationDidStart: async requestContext => {
        this.logger.debug('validationDidStart');
      },
      didResolveOperation: async requestContext => {
        this.logger.debug('didResolveOperation');
      },
      didEncounterErrors: async requestContext => {
        this.logger.error(requestContext.errors, 'didEncounterErrors');
      },
      willSendResponse: async requestContext => {
        this.logger.debug('willSendResponse');
      }
    };
  }
  filterVariables({
    request: {
      variables,
      query
    }
  }) {
    if (variables !== undefined && query !== undefined) {
      return this.dataFilter.document((0, graphql_1.parse)(query)).input(variables);
    }
    return variables;
  }
}
exports.ApolloLoggerPlugin = ApolloLoggerPlugin;
