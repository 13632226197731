"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoggerNotConfiguredError = void 0;
const ts_custom_error_1 = require("ts-custom-error");
class LoggerNotConfiguredError extends ts_custom_error_1.CustomError {
  constructor() {
    super('Logging has not been configured yet');
    Object.defineProperty(this, 'name', {
      value: 'LoggerNotConfiguredError'
    });
  }
}
exports.LoggerNotConfiguredError = LoggerNotConfiguredError;
